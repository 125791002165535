import { EventEmitter } from "events";
import { default as Cookies } from "./Cookies";
import fixWebmDuration from "webm-duration-fix";
import {default as navigationVersion} from "./navigatorVersion.js"

const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

class Recorder extends EventEmitter {
  constructor(data) {
    super();
    this._date = new Date();
    this._recordedBlobParts = [];
    this._record_blob_file = null;
    this._duration = 0;
    this._startTimeStamp = null;
    this._startTimeStampAbsolute = null;
    this._mimeType = data?.mimeType || null;
    this._audioBitsPerSecond = 128 * 1000;
    this._videoBitsPerSecond = data?.recordBitrate || null;
    this._intervaler = null;
    this._mediaRecorder = null;
    this._handledStream = null
    this._preview_el = data?.preview_el || null;
    this._audioDeviceId = null;
    this._videoDeviceId = null;
    //this._max_record_duration = (data?.max_record_duration != undefined ? data?.max_record_duration : 5 * 60000);
    this._metadata = navigationVersion;
  }

  init() {
    try {
      this._mediaRecorder = new MediaRecorder(this._handledStream, {
        mimeType: this._mimeType,
        audioBitsPerSecond: this._audioBitsPerSecond,
        videoBitsPerSecond: this._videoBitsPerSecond,
      });
      //console.debug("Created MediaRecorder", this._mediaRecorder);

      this._mediaRecorder.onstart = (evt) => {
        //ADD INFOS TO METADATA
        this._metadata.audioDevice = this._handledStream.getAudioTracks()[0].label
        this._metadata.videoDevice = this._handledStream.getVideoTracks()[0].label

        if (this._videoDeviceId == "screen") {
          this._metadata.videoDevice += " (screen)"
        }

        this._startTimeStamp = evt.timeStamp;
        this._startTimeStampAbsolute = new Date().getTime();
        this.emit("start", this);

        //COUNT RECORD DURATION
        this._duration = new Date().getTime() - this._startTimeStampAbsolute;
        this._intervaler = setInterval(() => {
          this._duration = new Date().getTime() - this._startTimeStampAbsolute;
          
          if (this._mediaRecorder.state == "recording") {
            this.emit("recording", this);
          } else {
            clearInterval(this._intervaler);
          }

        }, 1000);
      };

      this._mediaRecorder.ondataavailable = (evt) => {
        this._duration = evt.timeStamp - this._startTimeStamp;

        if (evt.data && evt.data.size > 0) {
          this._recordedBlobParts.push(evt.data);
        }
      };

      this._mediaRecorder.onstop = async () => {
        
        clearInterval(this._intervaler);

        let record_as_blob_raw = new Blob(this._recordedBlobParts, {
          type: this._mimeType,
        });

        try {
          this._record_blob_file = await fixWebmDuration(record_as_blob_raw);
        } catch (e) {
          console.log("Error during fixing record duration", e);
          this._record_blob_file = record_as_blob_raw;
        }

        this.emit("stop", this);
      };
    } catch (e) {
      console.error("Exception while creating MediaRecorder:", e);
      throw e;
    }
  }

  start(timeslice) {
    this._mediaRecorder.start(timeslice);
  }

  stop() {
    this._mediaRecorder.stop();
  }

  get duration() {
    return this._duration;
  }

  get readyToRecord(){
    return this._handledStream != null
  }

  changeMimeType(mimeType) {
    this._mimeType = mimeType;
    console.debug(`MimeType recording changed for : ${this._mimeType}`);
  }

  get mimeType() {
    return this._mimeType;
  }

  get record_blob_file() {
    return this._record_blob_file;
  }

  get audioinput() {
    return this._audioDeviceId;
  }

  get videoinput() {
    return this._videoDeviceId;
  }

  get videoSource() {
    let settings = this._handledStream.getVideoTracks()[0].getSettings();
    return {
      aspectRatio: settings.aspectRatio,
      frameRate: settings.frameRate,
      height: settings.height,
      width: settings.width,
    };
  }

  get metadata(){
    return this._metadata
  }

  handleSources(constraints) {
    return new Promise((resolve, reject) => {
      (async () => {
        //console.log('constraints :', constraints)
        try {
          navigator.mediaDevices.getUserMedia =
            navigator.mediaDevices.getUserMedia ||
            navigator.mediaDevices.webkitGetUserMedia ||
            navigator.mediaDevices.mozGetUserMedia;

          const constaintsMapped = {
            audio: {
              deviceId: (() => {
                if (
                  constraints != undefined &&
                  constraints.audioDeviceId != undefined
                ) {
                  return constraints.audioDeviceId;
                } else if (Cookies.get("audioinput") != null) {
                  return Cookies.get("audioinput");
                } else {
                  return undefined;
                }
              })(),
              echoCancellation: false,
              noiseSuppression: false,
              autoGainControl: false,
              mozNoiseSuppression: false,
              mozAutoGainControl: false,
            },
            video: {
              deviceId: (() => {
                if (
                  constraints != undefined &&
                  constraints.videoDeviceId != undefined
                ) {
                  return constraints.videoDeviceId;
                } else if (Cookies.get("videoinput") != null) {
                  return Cookies.get("videoinput");
                } else {
                  return undefined;
                }
              })(),
              //width: 1920,
              //height: 1080,
              width: { min: 200, ideal: 1920 },
              height: { min: 200, ideal: 1080 },
              frameRate: 25,
              autoGainControl: false,
              brightness: false,
              echoCancellation: false,
            },
          };

          //GET STREAM

          if (
            constraints != undefined &&
            constraints.videoDeviceId != undefined &&
            constraints.videoDeviceId == "screen"
          ) {
            let displayMedia = await navigator.mediaDevices
              .getDisplayMedia({
                video: {
                  mediaSource: "screen",
                },
                audio: false,
              })
              .catch(() => {
                reject();
              });
            let userMedia = await navigator.mediaDevices.getUserMedia({
              audio: true,
              video: false,
            });

            this._handledStream = new MediaStream([
              ...displayMedia.getTracks(),
              ...userMedia.getAudioTracks(),
            ]);
          } else {
            if (this._videoDeviceId == "screen") {
              this._handledStream.getTracks().forEach((track) => track.stop());
            }
            this._handledStream = await navigator.mediaDevices.getUserMedia(
              constaintsMapped
            );
          }

          this._audioDeviceId = this._handledStream
            .getAudioTracks()[0]
            .getCapabilities().deviceId;

          let streamSettings = this._handledStream
            .getVideoTracks()[0]
            .getSettings();
          if (
            constraints != undefined &&
            constraints.videoDeviceId != undefined &&
            constraints.videoDeviceId == "screen"
          ) {
            this._videoDeviceId = "screen";
          } else {
            this._videoDeviceId = this._handledStream
              .getVideoTracks()[0]
              .getCapabilities().deviceId;
          }

          //SAVE DEVICE ID USED IN COOKIE
          Cookies.set("audioinput", this._audioDeviceId);
          Cookies.set("videoinput", this._videoDeviceId);

          //WAIT UNTIL VIDEO PREVIEW IS PLAYING
          let previewIsPlaying = false;

          this._preview_el.addEventListener(
            "loadeddata",
            function () {
              previewIsPlaying = true;
            },
            false
          );

          //PREVIEW
          this._preview_el.srcObject = this._handledStream;
          this._preview_el.play();

          while (previewIsPlaying == false) {
            await sleep(200);
          }

          //console.debug(`Audio input handled : ${this._audioDeviceId}`);
          //console.debug(`Video input handled : ${this._videoDeviceId}`);

          resolve({
            audioDeviceId: this._audioDeviceId,
            videoDeviceId: this._videoDeviceId,
            streamSettings: streamSettings,
            stream: this._handledStream,
          });
        } catch (e) {
          reject(e);
        }
      })();
    });
  }

  static async scanInputs() {
    //GET INPUTS
    let gotDevices = await navigator.mediaDevices.enumerateDevices();

    /*
    let obsSourceIndex = videoInputs.findIndex(vi => /OBS/gm.test(vi.label))
    */
    
    return Promise.resolve({
      audioinputs: gotDevices.filter((device) => device.kind == "audioinput"),
      videoinputs: gotDevices.filter((device) => device.kind == "videoinput")
    });
  }

  stopStreams() {
    if (this._handledStream != null && this._handledStream.getTracks()) {
      this._handledStream.getTracks().forEach((track) => {
        track.stop();
      });
    }
  }
}

export default Recorder;
