<template>
    <div class="prompterContainer">
        <!-- INTERNAL PROMPTER-->
        <div id="toScroll" class="center" :style="to_scroll_style">
            <p class="prompter_content" :style="{ 'font-size': internal_prompter_size + 'px' }" ref="prompter_content">
                {{ currentScriptText }}
            </p>
        </div>
    </div>
</template>

<script>
import $ from "jquery"
import Vuex from "vuex";

export default {
    props: ["recording", 'currentScriptText', "scroll_width"],
    data() {
        return {
            prompterOffset: null,
            prompter_move: "stop",
            scrollingStep: 5,
        }
    },
    watch: {
        recording(recValue, previousRecValue) {
            if (recValue == true && previousRecValue == false) {

                setTimeout(() => {
                    //START PROMPTER
                    this.prompterOffset = $(".prompterContainer").height() + 20;
                    this.$nextTick(() => {
                        setTimeout(() => {
                            $("#toScroll").css({ top: this.prompterOffset + "px" });
                            this.scrolling();
                        }, 500);
                    });
                }, 0);
            } else if (recValue == false) {
                this.prompterOffset = null
            }
        },
    },
    computed: {
        to_scroll_style(){
            if (this.scroll_width != undefined){
                return `width : ${this.scroll_width}`
            } else { return ""}
        },
        ...Vuex.mapGetters([
            "internal_prompter_size",
            "internal_prompter_speed"
        ])
    },
    methods: {
        scrolling() {
            if (this.recording == true) {
                var scrollDuration = 150 / this.internal_prompter_speed;
                this.prompterOffset = this.prompterOffset - this.scrollingStep;

                $("#toScroll").animate({ top: this.prompterOffset + "px" },
                    scrollDuration,
                    "linear",
                    () => {
                        if (
                            this.prompterOffset > - $(".prompter_content").outerHeight() &&
                            this.recording == true
                        ) {
                            this.scrolling();
                        } else if (
                            this.prompterOffset <= -$(".prompter_content").outerHeight()
                        ) {
                            this.prompterOffset = $(".prompterContainer").height();
                            $("#toScroll").css({ top: this.prompterOffset + "px" });
                            this.scrolling();
                        }
                    }
                );
            }
        },
    }

}
</script>

<style src="./InternalPrompter.css" scoped >
</style>