<template>
    <div id="guides" class="guides_main center">
    <span class="guide_top_head center">
        <p class="guide_name center">{{ $t('Guides.topOfHead') }}</p>
       
     </span>
     <span v-if="vertical_mode == false" class="guide vertical center">
        <p class="guide_name">VERTICAL</p>
     </span>
     <span v-if="vertical_mode == false" class="guide square center">
         <p class="guide_name">SQUARE</p>
     </span>
    </div>
</template>

<script>
export default {
    props : {
        vertical_mode : {default : false}
    }
}
</script>

<style scoped>
.guides_main{
    height:100%;
    position:absolute;
    z-index: 4;
    pointer-events: none;
}


.guide{
    position:absolute;
    height:100%;
    border: 3px solid var(--color);
    border-top-width: 0px;
    border-bottom-width: 0px;
}

.vertical{
    --color: #C1A7E2;
    aspect-ratio: 9/16;
}

.square{
    --color: #47D7AC;
    aspect-ratio: 1;
}

.guide_name{
    font-weight: 500;
    margin: 0px;
    position: absolute;
    top: 0px;
    right: 0px;
    transform: rotate(-90deg) translateX(-20px);
    transform-origin: bottom right;
    color: var(--color)
}

.guide_top_head{
    --color:#F8AD6D;
    position:absolute;
    height:94%;
    top:6%;
    width:100%;
    border: 3px solid var(--color);
    border-bottom-width: 0px;
    border-right-width: 0px;
    border-left-width: 0px;
}

.guide_top_head > .guide_name{
    top: -8px;
    right: 0px;
    transform: rotate(0deg) translateY(-100%);
    transform-origin: unset;
    text-align: center;
}
</style>