<template>
  <div class="main">

    <!-- CODECS-->
    <div v-if='changeCodecPanel == true' style="position:absolute; z-index:5; background-color: #00000050;">
      <p style="color:white" v-for="(mimeType, index) in possibleRecordingMimesTypes" :key="mimeType" @click="noCodec = false; possibleRecordingMimesTypes.move(index, 0)">
        <a v-if="getSupportedMimeTypes.includes(mimeType)" style="color:#89ff89">{{ mimeType }}</a>
        <a v-else>{{ mimeType }} </a>
      </p>
      <p style="color:white" @click="noCodec = true">
        <a>No Codec</a>
      </p>
    </div>

    <!-- SETTINGS BUTTON  -->
    <div class="settings_button_container" ref="settings_button_container" @click="show_setting = !show_setting">
      <img src="../../assets/gear.png" />
    </div>

    <!-- PANEL SETTINGS -->
    <transition name="panel-slide">
      <PanelSettings_mobile @finished="show_setting = false" v-if="show_setting == true && navigationVersion.mobile" panel-position="full" :recorder="recorder" :biterate="recordBitrate" :codec="getSupportedMimeTypes[0]" :flip_camera="flip_camera" :guides="guides" :changeCodecPanel="changeCodecPanel" @change_guides="(value) => { guides = value }" @change_biterate="(biterate) => {
          recordBitrate = biterate;
        }
        " @change_flip_camera="(flip) => {
      flip_camera = flip;
    }
    " @changeCodecPanel="(value) => { changeCodecPanel = value }" />

      <PanelSettings_desktop @finished=" show_setting = false" v-else-if="show_setting == true" panel-position="30" :biterate="recordBitrate" :guides="guides" :codec="getSupportedMimeTypes[0]" :flip_camera="flip_camera" :changeCodecPanel="changeCodecPanel" :socketHandled="socketHandled" :recorder="recorder" @change_biterate="(biterate) => { recordBitrate = biterate }" @change_flip_camera="(flip) => { flip_camera = flip }" @changeCodecPanel="(value) => { changeCodecPanel = value }" @change_guides="(value) => { guides = value }" />
    </transition>

    <!-- CLICK ZONE TO CLOSE PANEL -->
    <span class="click_zone_to_close_panel" v-if="show_setting == true && !navigationVersion.mobile" @click=" show_setting = false "></span>

    <!-- INTERNAL PROMPTER -->
    <InternalPrompter v-if="internal_prompter_enable == true && currentScriptText != ''" :currentScriptText="currentScriptText" :class="`${internal_prompter_class_position} ${messageOverlay.show == true ? 'hide' : ''
      }`" :recording="recording" :hide="messageOverlay.show" :scroll_width="navigationVersion.mobile ? '' : '400px'" />

    <p ref="videotrackinfo" class="videotrackinfo" style="opacity: 0; display: none;"></p>

    <!-- PREVIEW -->
    <div class="video_aera">
      <div class="messageOverlay" :class="messageOverlay.show == true ? 'active' : ''">
        <h2>{{ messageOverlay.line1 }}</h2>
        <h2>{{ messageOverlay.line2 }}</h2>
        <h2>{{ messageOverlay.line3 }}</h2>
      </div>

      <div class="rec_toggle center" :class="{'active' : readyToRecord}" @click="toggleRec()" v-tippy="rec_toggle_cant_rec_tippy_config">
        <span class="rec_stop_icon" :class="recording == true ? 'stop' : 'rec'" ></span>
        <!---->
        <span class="separator" v-if="recording == true"></span>
        <p class="recording_tc" v-if="recording == true">
          {{ ms2tc(recorder.duration) }}
        </p>
      </div>

      <!-- RED SQUARE DURING RECORDING -->
      <span class="rec_square" :class="recording == true ? 'active' : ''"></span>

      <Guides v-if="guides" :vertical_mode="this.orientation == 0" />

      <video class="preview video center" :class="flip_camera == true ? 'flip' : ''" autoplay reload="auto" x-webkit-airplay="allow" playsinline="" muted ref="preview"></video>
    </div>
  </div>
</template>

<script>
//COMPONENTS
import PanelSettings_mobile from "./components/Panel_settings_mobile/Panel_settings_mobile.vue";
import PanelSettings_desktop from "./components/Panel_settings_desktop/Panel_settings_desktop.vue";

import InternalPrompter from "./components/InternalPrompter/InternalPrompter.vue";

import Guides from './components/Guides.vue'

//RECORDER
import Recorder from "../../lib/recorder"
import { default as navigationVersion } from "../../lib/navigatorVersion"


const videoDuration = require("../../lib/time"); // eslint-disable-line


Array.prototype.move = function (from, to) {
  this.splice(to, 0, this.splice(from, 1)[0]);
};

//STORE
import Vuex from "vuex";
import $ from "jquery"

export default {
  components: {
    PanelSettings_mobile,
    PanelSettings_desktop,
    InternalPrompter,
    Guides
  },
  emits: ["recording_finished"],

  data() {
    return {
      socketHandled: this.$store.state.socketInstance,
      //RECORDING
      changeCodecPanel: false,
      noCodec: false,
      recording: false,
      recorder: null,
      recordBitrate: 7.5 * 1000000,
      navigationVersion: navigationVersion,

      flip_camera: (() => {
        if (this.$cookies.get("flip_camera") != null) {
          return (this.$cookies.get("flip_camera") == "true" ? true : false)
        } else {
          return true
        }
      })(),
      guides: (() => {
        if (this.$cookies.get("guides") != null) {
          return (this.$cookies.get("guides") == "true" ? true : false)
        } else {
          return true
        }
      })(),
      show_setting: false,
      messageOverlay: {
        show: false,
        line1: this.$t("Record.setSilence"),
        line2: "1",
      },

      orientation: window.orientation,
    };
  },
  async mounted() {
    console.log(this.navigationVersion)
    try {
      let project_id = this.$route.query.project_id;
      let clip_id = this.$route.query.clip_id;

      if (project_id == undefined || clip_id == undefined) {
        //this.Toaster.error(this.$t("errors.videoData"));
        //return;
        this.$router.push({
          name: "Error"
        })
      }

      //BONJOUR EMIT
      //WAITING FOR SOCKET CONNECTION
      while (this.socketHandled == null) {
        console.debug("Waiting for socket connection");
        await this.sleep(1000);
      }

      //DISCOVERING
      if (this.project_id == null) {
        await this.emit_discovering_socket_event();
      }

      //CREATE RECORDER INSTANCE
      this.recorder = new Recorder({
        mimeType: this.getSupportedMimeTypes[0],
        recordBitrate: this.recordBitrate,
        preview_el: this.$refs.preview,
        //max_record_duration: this.recording_time_limit
      });

      // VERIFY OR ASK FOR INPUT SOURCES ACCESS AND DISPLAY SOURCES STREAM PREVIEW 
      this.recorder.handleSources()
        .then(() => {
          this.fitsGuidesSize()
        })

      addEventListener("resize", () => {
        this.fitsGuidesSize()
      });

      //SETTING UP RECORDER EVENTS
      this.recorder.on('stop', evt => {
        console.log(this.localRecords)
        //SAVE RECORD
        this.localRecords.push({
          duration: evt.duration,
          mimeType: evt.mimeType,
          record_blob_file: evt.record_blob_file,
          clip_id: this.currentClipId,
          media_type: evt._videoDeviceId == 'screen' ? 'screen_record' : 'video_record',
          metadata: evt.metadata,
          name: this.$t('Replay.take') + " " + (this.localRecords.filter(r => r?.validated == undefined).length + 1)
        });

        this.generateRecordsBlobThumbnails()

        //this.$emit('recording_finished')
        this.$router.push({
          path: "/replay",
          query: {
            ...this.$route.query
          },
        });
      })

      this.recorder.on('recording', evt => {
        this.socketHandled.emit("recording", {
          recording: true,
          duration: evt.duration
        });
      })

      this.recorder.on('start', async (evt) => {
        console.debug("Recroding started EMITTED ", evt);
        this.recording = true;

        //SEND RECORDING STATE TO PROMPTER
        this.socketHandled.emit("recording", {
          recording: true,
          duration: 0,
        });

        //DISPLAY MESSAGE "ACTION"
        this.messageOverlay.line1 = ''
        this.messageOverlay.line2 = 'ACTION ! 🎥'
        await this.sleep(1000)
        this.messageOverlay.show = false
      });
    } catch (e) {
      console.error(e)
    }
    //ORIENTATION DETECTION
    window.onorientationchange = () => {
      this.orientation = window.orientation;
    };
  },
  computed: {
    ...Vuex.mapGetters([
      "currentClipId",
      "currentClip",
      "localRecords",
      "internal_prompter_enable",
      "recording_time_limit",
      "record_limitation_message",
      "loading",
      "project_id",
      "currentScriptText"
    ]),

    getSupportedMimeTypes() {
      if (this.noCodec == true) {
        return []
      }

      if (this.navigationVersion.mobileOperatingSystem == "iOS") {
        return ['video/mp4; codecs="avc1.424028, mp4a.40.2"']
      }

      return this.possibleRecordingMimesTypes.filter((mimeType) => {
        return MediaRecorder.isTypeSupported(mimeType);
      });
    },
    rec_toggle_cant_rec_tippy_config(){
      return {
          offset: [0, 30],
          content: this.$t('Record.camera_access_denied'),
          maxWidth: 270,
          theme: 'light',
          trigger : (!this.readyToRecord ? 'mouseenter focus' : 'manual')
      }
    },

    internal_prompter_class_position() {
      if (this.orientation == 90) {
        return "left";
      } else if (this.orientation == -90) {
        return "right";
      } else if (this.orientation == 0) {
        return "top";
      } else {
        return "top";
      }
    },
    readyToRecord() {
      return this.recorder?.readyToRecord || false
    }
  },
  watch: {
    internal_prompter_speed(val) {
      this.$cookies.set("internal_prompter_speed", val);
    },
    internal_prompter_size(val) {
      this.$cookies.set("internal_prompter_size", val);
    },
    flip_camera(val) {
      this.$cookies.set("flip_camera", val);
    },
    internal_prompter_enable(val) {
      this.$cookies.set("internal_prompter_enable", val);
    },
    guides(val) {
      this.$nextTick(() => {
        this.fitsGuidesSize()
      });
      this.$cookies.set("guides", val)
    },
    recording(val) {
      if (val == true) {
        document.title = this.$t('DocumentTitle.recording')
      } else {
        document.title = this.$t('DocumentTitle.home')
      }
    }
  },
  methods: {
    ...Vuex.mapActions([
      "emit_discovering_socket_event",
      "generateRecordsBlobThumbnails"
    ]),
    /*
    getMobileOperatingSystem() {
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;

      // Windows Phone must come first because its UA also contains "Android"
      if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
      }

      if (/android/i.test(userAgent)) {
        return "Android";
      }

      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
      }

      return "unknown";
    },
    */
    toggleRec(){
      if (this.readyToRecord){
        this.recording == true ? this.stop_record() : this.start_record()
      }
    },
    ms2tc: videoDuration.ms2tc,
    changeMimeType() {
      this.recorder.changeMimeType(this.getSupportedMimeTypes[0])
    },

    async start_record() {
      //INITIALISE THE RECORDER INSTANCE
      try {
        this.recorder.init()
      } catch (e) {
        console.error("Exception while creating MediaRecorder:", e);
        return;
      }

      //DISPLAY COUNTDOWN
      this.preStartMessageOverlay();
      await this.sleep(3000)

      //START TO RECORD VIDEO
      this.recorder.start();
    },

    stop_record() {
      console.log("stop record")
      this.recorder.stop();
      this.recording = false;

      //SEND RECORDING STATE TO PROMPTER
      this.socketHandled.emit("recording", {
        recording: false,
      });
    },

    async preStartMessageOverlay() {
      //MESSAGE OVERLAY
      this.messageOverlay.show = true
      this.messageOverlay.line1 = this.$t('Record.setSilence')
      this.messageOverlay.line2 = '3'
      await this.sleep(1000)
      this.messageOverlay.line2 = '2'
      await this.sleep(1000)
      this.messageOverlay.line2 = '1'
    },
    fitsGuidesSize() {
      let originalSource = this.recorder.videoSource
      let originalWidth = originalSource.width;
      let originalHeight = originalSource.height
      let targetWidth = $(this.$refs.preview).width();
      var targetHeight = $(this.$refs.preview).height();
      let scale = originalWidth / originalHeight > targetWidth / targetHeight ? targetWidth / originalWidth : targetHeight / originalHeight;
      $('#guides').css({ 'width': originalSource.width * scale, "height": originalSource.height * scale })
    },
  },
  beforeUnmount() {
    if (this.recorder != null) {
      this.recorder.stopStreams()
    }
  },
};
</script>

<style src="./recorder.css" scoped></style>
<style src="./rec_toggle.css" scoped></style>
<style src="./settings_button.css" scoped></style>
