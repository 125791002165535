<template>
  <div class="panel">
    <h2>{{ $t('Settings.title') }}</h2>

    <!-- SOURCE VIDEO -->
    <div class="input center">
      <h3>{{ $t('Settings.videoSrc') }}</h3>
      <select list="videoinputs" ref="videoSourceSelect" v-model="videoinput" @change="init">
        <option id="videoinputs" v-for="input in local_videoinputs" :key="input" :value="input.deviceId">
          {{ input.label }}
        </option>
      </select>
    </div>

    <!-- SOURCE AUDIO -->
    <div class="input center">
      <h3>{{ $t('Settings.audioSrc') }}</h3>
      <select list="audioinputs" ref="audioSourceSelect" v-model="audioinput" @change="init">
        <option id="audioinputs" v-for="input in local_audioinputs" :key="input" :value="input.deviceId">
          {{ input.label }}
        </option>
      </select>
    </div>

    <div class="input center">
      <h3>{{ $t('Settings.mirrorCamera') }}</h3>
      <Toggle v-model="local_flip_camera" id="flip_camera" style="display: inline-block;" />
      <p class="label">{{ local_flip_camera == true ? $t('Settings.enable') : $t('Settings.disable') }}</p>
    </div>

    <div class="input center">
      <h3>{{ $t('Settings.guides') }}</h3>
      <Toggle v-model="local_guides" id="show_guides" style="display: inline-block;" />
      <p class="label">{{ local_guides == true ? $t('Settings.enable') : $t('Settings.disable') }}</p>
    </div>

    <!-- INTERNAL PROMPTER -->
    <div class="input center">
      <h3>{{ $t('Settings.internalPrompter') }}</h3>

      <Toggle v-model="internal_prompter_enable" id="prompter_internal_active" style="display: inline-block;" />
      <p class="label">{{ internal_prompter_enable == true ? $t('Settings.enable') : $t('Settings.disable') }}</p>

      <div class="prompterSetting">

        <label for="speed">{{ $t('Settings.prompterSpeed') }}</label>
        <div>
          <img src="../../../../assets/trotinette.svg" />
          <input v-model="internal_prompter_speed" type="range" id="speed" name="speed" min="0.4" max="3.6" step="0.2" />
          <img src="../../../../assets/rocket.svg" />
        </div>
      </div>

      <div class="prompterSetting size">
        <label for="speed">{{ $t('Settings.prompterSize') }}</label>
        <div>
          <p class="small"><a>Aa</a></p>
          <input v-model="internal_prompter_size" type="range" id="size" name="size" min="20" max="45" step="5" />
          <p class="large"><a>Aa</a></p>
        </div>
      </div>
    </div>

    <!-- PROMPTER EXTERNE -->
    <div class="input center">
      <h3>{{ $t('Settings.externalPrompter') }}</h3>

      <div class="prompterSetting qrcode">
        <img ref="prompterCode" id="prompter_qr_code" :src="$store.state.prompterQrCode" @click="copyToClipboard(prompterUrl)" />
      </div>

      <div class="prompterSetting">
        <label for="speed">{{ $t('Settings.prompterSpeed') }}</label>
        <div>
          <img src="../../../../assets/trotinette.svg" />
          <input v-model="prompter_speed" @change="changePrompterSpeed(prompter_speed)" type="range" id="speed"
            name="speed" min="0.4" max="3.6" step="0.2" />
          <img src="../../../../assets/rocket.svg" />
        </div>
      </div>

      <div class="prompterSetting size">
        <label for="speed">{{ $t('Settings.prompterSize') }}</label>
        <div>
          <p class="small"><a>Aa</a></p>
          <input v-model="prompter_size" @change="changePrompterSize(prompter_size)" type="range" id="size" name="size"
            min="35" max="60" step="5" />
          <p class="large"><a>Aa</a></p>
        </div>
      </div>

      <div class="prompterSetting">
        <p style="font-weight: 300;">{{ $t('Settings.mirror') }}</p>
        <Toggle id="mirror" v-model="prompter_flip" @update:modelValue="changePrompterFlip(prompter_flip)"
          style="display: inline-block;" />
        <p class="label">{{ prompter_flip == true ? $t('Settings.enable') : $t('Settings.disable') }}</p>
      </div>

      <!-- SHOW CODEC PANEL -->
      <div>
        <p><a @click="$emit('changeCodecPanel', !changeCodecPanel)">© Copyright Yopbox 2023</a></p>
      </div>


    </div>

  </div>
</template>


<script>

//STORE
import Vuex from "vuex";

//RECORDER
import Recorder from "../../../../lib/recorder"

export default {
  data() {
    return {
      socketHandled: this.$store.state.socketInstance,

      local_audioinputs: [],
      local_videoinputs: [],
      local_biterate: 20 * 1000000,
      local_internal_prompter: true,
      local_flip_camera: true,
      local_guides: true,


      //PROMPTER
      prompter_speed: null,
      prompter_size: null,
      prompter_flip: true,

      stream: null
    };
  },
  emits: ["change_biterate", "change_internal_prompter", "change_flip_camera", "changeCodecPanel", "change_guides", "screen_record"],
  props: [
    "biterate",
    "codec",
    "guides",
    "flip_camera",
    "changeCodecPanel",
    "recorder"
  ],
  mounted() {

    //SETTING UP INPUT SELECTOR
    Recorder.scanInputs().then((results) => {
      this.local_audioinputs = results.audioinputs
      this.local_videoinputs = results.videoinputs
      this.local_videoinputs.push({ label: this.$t('Settings.screenRecording'), deviceId: 'screen' })

    })

    //this.local_audioinput = this.audioinput;
    //this.local_videoinput = this.videoinput;
    this.local_biterate = this.biterate;
    //this.local_internal_prompter = this.internalPrompter;
    this.local_flip_camera = this.flip_camera;
    this.local_guides = this.guides;



    (async () => {
      //WAITING FOR SOCKET CONNECTION
      while (this.socketHandled == null) {
        //console.debug('Waiting for socket connection')
        await this.sleep(1000);
      }

      this.socketHandled.emit("prompter", {}, (prompterSettings) => {
        this.prompter_speed = prompterSettings.prompter_speed;
        this.prompter_size = prompterSettings.prompter_size;
        this.prompter_flip = prompterSettings.prompter_flip;
      });
    })();
  },
  watch: {
    local_biterate(val) {
      this.$emit("change_biterate", val);
    },
    local_internal_prompter(val) {
      this.$emit("change_internal_prompter", val);
    },
    local_flip_camera(val) {
      this.$emit("change_flip_camera", val);
    },
    /*
    prompter_speed(val){
      //console.debug(val)
    }
    */
    local_guides(val) {
      this.$emit("change_guides", val);
    }

  },
  computed: {
    ...Vuex.mapGetters([
      "prompterUrl",
      //"internal_prompter_enable",
      //"internal_prompter_speed",
      //"internal_prompter_size"
    ]),
    audioinput: {
      get() {
        return this.recorder.audioinput
      },
      set(val) {
        this.recorder.handleSources({ audioDeviceId: val })
      }
    },
    videoinput: {
      get() {
        return this.recorder.videoinput
      },
      set(val) {
        if (val == 'screen') {
          this.local_flip_camera = false
        }
        this.recorder.handleSources({ videoDeviceId: val })
      }
    },
    internal_prompter_enable: {
      get() {
        return this.$store.state.internal_prompter_enable
      },
      set(val) {
        this.$store.state.internal_prompter_enable = val
      }
    },
    internal_prompter_speed: {
      get() {
        return this.$store.state.internal_prompter_speed
      },
      set(val) {
        this.$store.state.internal_prompter_speed = val
      }
    },
    internal_prompter_size: {
      get() {
        return this.$store.state.internal_prompter_size
      },
      set(val) {
        this.$store.state.internal_prompter_size = val
      }
    }
  },
  methods: {
    ...Vuex.mapActions([
      "changePrompterSpeed",
      "changePrompterSize",
      "changePrompterFlip"
    ]),
    /*
    //PROMPTER
    changePrompterSpeed() {
      this.socketHandled.emit("prompter", {
        prompter_speed: Number(this.prompter_speed),
      });
    },
    changePrompterSize() {
      this.socketHandled.emit("prompter", {
        prompter_size: Number(this.prompter_size),
      });
    },
    changePrompterFlip() {
      this.socketHandled.emit("prompter", {
        prompter_flip: this.prompter_flip,
      });
    },
    */

  },
};
</script>

<style src="./panel_settings.css" scoped></style>

<style src="./prompter_settings.css" scoped></style>