<template>
  <div class="settings">

    <!-- CAPTURE -->
    <h3>Capture</h3>

    <div class="capture center">
      <div @click="changeVideoInput()">
        <img src="../../../../assets/switch-camera.png">
        <a>Video</a>
      </div>
      <div @click="local_flip_camera = !local_flip_camera">
        <img src="../../../../assets/flip_camera.png" :class="(local_flip_camera == true ? 'flipped' : '')">
        <a>Flip</a>
      </div>
      <div @click="local_guides = !local_guides">
        <img src="../../../../assets/guides.svg" :class="(local_guides == false ? 'guides_disabled' : '')">
        <a>Guides</a>
      </div>
      <div>
        <img src="../../../../assets/mic_white.png" @click="openAudioInputList()">
        <a>{{$t('Settings.sound')}}</a>
        <select class="audioInputSelect" list="audioinputs" ref="audioSourceSelect" v-model="audioinput">
          <option id="audioinputs" v-for="input in local_audioinputs" :key="input" :value="input.deviceId">
            {{ input.label }}
          </option>
        </select>
      </div>
    </div>





    <!-- PROMPTER INTERNE -->
    <h3>{{$t('Settings.integratedPrompter')}}</h3>
    <div class="internal_prompter_setting center" style="justify-content: center;">

      <div class="local_internal_prompter_enable" 
        @click="internal_prompter_enable = !internal_prompter_enable">
        <hr v-if="local_internal_prompter_enable == false" />
        <img src="../../../../assets/internal_prompter_white.png">
        <a >{{$t('Settings.integratedPrompter')}}</a>
      </div>



    </div>

    <div class="input center">

      <div class="prompterSetting">
        <label for="speed">{{$t('Settings.prompterSpeed')}}</label>
        <div>
          <img src="../../../../assets/trotinette.svg" />
          <input v-model="internal_prompter_speed" type="range" id="speed" name="speed" min="1" max="10" />
          <img src="../../../../assets/rocket.svg" />
        </div>
      </div>

      <div class="prompterSetting size">
        <label for="speed">{{$t('Settings.prompterSize')}}</label>
        <div>
          <p class="small"><a>Aa</a></p>
          <input v-model="internal_prompter_size" type="range" id="size" name="size" min="30" max="70"
            step="10" />
          <p class="large"><a>Aa</a></p>
        </div>
      </div>
    </div>

    <!-- PROMPTER EXTERNE -->
    <div class="input center">
      <h3>{{$t('Settings.externalPrompter')}}</h3>

      <div class="prompterSetting qrcode">
        <img ref="prompterCode" :src="$store.state.prompterQrCode" @click="copyToClipboard(prompterUrl)" />
      </div>

      <div class="prompterSetting">
        <label for="speed">{{$t('Settings.prompterSpeed')}}</label>
        <div>
          <img src="../../../../assets/trotinette.svg" />
          <input v-model="remote_prompter_speed" @change="changeRemotePrompterSpeed()" type="range" id="speed"
            name="speed" min="1" max="10" />
          <img src="../../../../assets/rocket.svg" />
        </div>
      </div>

      <div class="prompterSetting size">
        <label for="speed">{{$t('Settings.prompterSize')}}</label>
        <div>
          <p class="small"><a>Aa</a></p>
          <input v-model="remote_prompter_size" @change="changeRemotePrompterSize()" type="range" id="size" name="size"
            min="35" max="60" step="5" />
          <p class="large"><a>Aa</a></p>
        </div>
      </div>

      <div class="prompterSetting">
        <p style="font-weight: 300;">{{$t('Settings.mirror')}}</p>
        <Toggle id="mirror" v-model="remote_prompter_flip" @update:modelValue="changeRemotePrompterFlip"></Toggle>
      </div>

      <!-- SHOW CODEC PANEL -->
      <div>
        <p><a @click="$emit('changeCodecPanel', !changeCodecPanel)">© Copyright Yopbox 2023</a></p>
      </div>
    </div>

  </div>
</template>

<script>

//STORE
import Vuex from "vuex";

//RECORDER
import Recorder from "../../../../lib/recorder"

import {default as navigationVersion} from "../../../../lib/navigatorVersion"


export default {
  data() {
    return {
      socketHandled : this.$store.state.socketInstance,
      navigationVersion : navigationVersion,
      
      local_audioinputs: [],
      local_videoinputs: [],
      local_biterate: 20 * 1000000,
      local_flip_camera: true,
      local_guides : true,

      //INTERNAL PROMPTER
      local_internal_prompter_enable: true,
      local_internal_prompter_speed: null,
      local_internal_prompter_size: null,

      //REMOTE PROMPTER
      remote_prompter_speed: null,
      remote_prompter_size: null,
      remote_prompter_flip: true
    };
  },
  props: [
    "recorder",
    "biterate",
    "codec",
    "flip_camera",
    "guides",
    "changeCodecPanel"
  ],
  emits: [
    "change_biterate",
    "change_flip_camera",
    "changeCodecPanel",
    "change_guides"
  ],
  mounted() {

    //SETTING UP INPUT SELECTOR
    Recorder.scanInputs().then((results)=>{
      this.local_audioinputs = results.audioinputs
      this.local_videoinputs = results.videoinputs
    })

    this.local_biterate = this.biterate;
    this.local_flip_camera = this.flip_camera;
    this.local_guides = this.guides;


    (async () => {
      //WAITING FOR SOCKET CONNECTION
      while (this.socketHandled == null) {
        //console.debug('Waiting for socket connection')
        await this.sleep(1000);
      }

      this.socketHandled.emit("prompter", {}, (prompterSettings) => {
        this.remote_prompter_speed = prompterSettings.prompter_speed;
        this.remote_prompter_size = prompterSettings.prompter_size;
        this.remote_prompter_flip = prompterSettings.prompter_flip;
      });
    })();
  },
  watch: {
    local_biterate() {
      this.$emit("change_biterate", this.local_biterate);
    },
    local_flip_camera(val) {
      this.$emit("change_flip_camera", val);
    },
    local_guides(val) {
      this.$emit("change_guides", val);
    },
  },
  computed: {
    ...Vuex.mapGetters([
      "prompterUrl"
    ]),
    audioinput : {
      get() {
        return this.recorder.audioinput
      },
      set(val){
        this.recorder.handleSources({audioDeviceId : val})
      }
    },
    videoinput : {
      get() {
        return this.recorder.videoinput
      },
      set(val){
        this.recorder.handleSources({videoDeviceId : val})
      }
    },
    internal_prompter_enable : {
      get() {
        return this.$store.state.internal_prompter_enable
      },
      set(val){
        this.$store.state.internal_prompter_enable = val
      }
    },
    internal_prompter_speed : {
      get() {
        return this.$store.state.internal_prompter_speed
      },
      set(val){
        this.$store.state.internal_prompter_speed = val
      }
    },
    internal_prompter_size : {
      get() {
        return this.$store.state.internal_prompter_size
      },
      set(val){
        console.log(this.$store.state)
        this.$store.state.internal_prompter_size = val
      }
    }
  },
  methods: {
    openAudioInputList() {
      var element = this.$refs.audioSourceSelect;
      var worked = false;
      if (document.createEvent) { // chrome and safari
        var e = document.createEvent("MouseEvents");
        e.initMouseEvent("mousedown", true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
        worked = element.dispatchEvent(e);
      }
      if (!worked) { // unknown browser / error
        //alert("It didn't worked in your browser.");
      }
    },

    changeVideoInput() {
      
      var index = this.local_videoinputs.findIndex(input => input.deviceId == this.videoinput)
      if (index == -1) {
        index = 0
      }

      if (index + 1 > this.local_videoinputs.length - 1) {
        index = 0
      } else {
        index = index + 1
      }

      if (this.local_videoinputs[index] != undefined && this.local_videoinputs[index].deviceId != undefined) {
        //this.$emit("change_video", this.videoinputs[index].deviceId);
        this.videoinput = this.local_videoinputs[index].deviceId
        console.debug('videoinput', this.videoinput)
      }
    },


    //PROMPTER
    changeRemotePrompterSpeed() {
      this.socketHandled.emit("prompter", {
        prompter_speed: Number(this.remote_prompter_speed),
      });
    },
    changeRemotePrompterSize() {
      this.socketHandled.emit("prompter", {
        prompter_size: Number(this.remote_prompter_size),
      });
    },
    changeRemotePrompterFlip() {
      this.socketHandled.emit("prompter", {
        prompter_flip: this.remote_prompter_flip,
      });
    },

  },
};
</script>

<style src="./panel_settings.css">
</style>

<style src="./prompter_settings.css">
</style>